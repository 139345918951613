import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import './App.scss';

import { LocalizeProvider } from "react-localize-redux";

import InitApp from './helpers/InitApp';

import Home from './pages/home';

import PatientStart from './pages/start';
import PatientMeasure from './pages/measure';
import PatientFinal from './pages/final';
import SplitQuestion from './pages/question';

function App() {
  return (
	  <LocalizeProvider>
		  <InitApp>
		  	<Router>
			  	<AnimatedSwitch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className="switch-wrapper" >
			      <Route exact path="/" render={() => <Redirect to="/home" />} />
			      <InitedRoute path="/home" component={Home} />
            <InitedRoute exact path="/patient" component={PatientStart} />
            <Route path="/patient" component={RoutePatient} />

			    </AnimatedSwitch>
				</Router>
			</InitApp>
		</LocalizeProvider>
  );
}

function RoutePatient({ match }) {
  return (
    <div>
      <div className="split-panel">
        <AnimatedSwitch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className="switch-wrapper split-pane" >
          <InitedRoute path={match.path+'/measure'} component={PatientMeasure} />
          <InitedRoute path={match.path+'/final'} component={PatientFinal} />
        </AnimatedSwitch>
        <div className="split-pane"><SplitQuestion /></div>
      </div>
    </div>
  );
}

function InitedRoute({ component: Component, ...rest }) {
  let isInited = false;
  isInited = true;

  return (
    <Route
      {...rest}
      render={props =>
        isInited ? (
          <Component {...props} />
        ) : (
          <div>Loading</div>
        )
      }
    />
  );
}

export default App;
