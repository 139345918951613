import React, { Component } from "react";
import { connect } from 'react-redux'
import { patientChange, patientClear } from '../state/patient'

import authFetch from '../services/authFetch';

import Loading from '../components/Loading';

import MiniLanguageToggle from '../components/MiniLanguageToggle';
import MiniClock from '../components/MiniClock';

import { Translate } from "react-localize-redux";


class PatientFinal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: null,
            stats: null,
            loading: false,
            bmi: null,
            counter: 30
        };

        this.timer = null;
    }


    componentDidMount() {
        if (this.props.patient.vn === null) {
            this.props.history.push('/');
        } else {
            if(!this.props.patient.splitting) {
                this.timer = setInterval(() => {
                    if(this.props.patient.splitting){
                        
                    } else {
                        let counter = this.state.counter - 1;
    
                        if (counter === 0) {
                            clearInterval(this.timer);
                            this.saveAndGo();
                        }
                        this.setState({ counter: counter });
                    }
                }, 1000)
                this.saveAndGo();
            }
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    cancel = () => {
        //this.props.patientChange( { weight: this.state.weight } );
        this.props.history.push('/');
    }

    saveAndGo = () => {
        //do save to server

        this.setState({ loading: true });
        let data = {
            vn: this.props.patient.vn,
            tel: this.props.patient.tel,
            wt: this.props.patient.wt,
            ht: this.props.patient.ht,
            sbp: this.props.patient.sbp,
            dbp: this.props.patient.dbp,
            pulse: this.props.patient.pulse,
            personal: this.props.patient.personals
        }
        authFetch.post('visit/device', data, (result) => {
            this.setState({ loading: false });
            this.props.patientClear();
            this.props.history.push('/patient');
        });
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div className="page">
                        <main className="page-center">

                            <div className="logout-wrapper">
                                <div className="menu-topbar">
                                    <MiniClock />
                                    <MiniLanguageToggle />
                                </div>
                                <div className="menu-topbar">
                                    <button onClick={() => { this.props.history.push('/') }}><i className="fa fa-home"></i></button>
                                </div>
                            </div>

                            <div>
                                <div className="page-title">
                                    <h1>{translate("your_result")}</h1>
                                    <h2>{this.props.patient.fullname}</h2>
                                    <br />
                                    {this.props.patient.wt?
                                    <div>
                                        <h3>
                                            {translate("weight")} {this.props.patient.wt} {translate("kg")} 
                                            {this.props.patient.ht?
                                                <span> / {translate("height")} - {this.props.patient.ht} {translate("cm")}</span>
                                            :null}
                                        </h3>
                                        {this.props.patient.bmi ?
                                            <h4>BMI {this.props.patient.bmi.bmi} {translate(this.props.patient.bmi.meaning + "")}</h4>
                                        :null}
                                    </div>
                                    :null}

                                    {this.props.patient.sbp&&this.props.patient.dbp?
                                    <div>
                                        <h3>{translate("blood_pressure")} {this.props.patient.sbp}/{this.props.patient.dbp} <small><i className="fas fa-heartbeat"></i> {translate("pulse")} {this.props.patient.pulse}</small></h3>
                                        <p>({translate('please_contact_admin_if_wrong_info')})</p>
                                    </div>
                                    :null}
                                    <button onClick={this.saveAndGo} className="button">{translate("confirm")}
                                    {!this.props.patient.splitting?
                                        <span>({this.state.counter})</span>
                                    :null}
                                    &nbsp;
                                     <i className="fas fa-check"></i></button>
                                </div>
                            </div>
                        </main>
                        <Loading show={this.state.loading} />
                    </div>
                }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange, patientClear })(PatientFinal);
