import React, { Component } from "react";
import { connect } from 'react-redux'
import { patientChange, patientClear } from '../state/patient'

import authFetch from '../services/authFetch';
import MainLogo from '../components/MainLogo';
import Loading from '../components/Loading';
import MiniLanguageToggle from '../components/MiniLanguageToggle';
import MiniClock from '../components/MiniClock';

import { Translate } from "react-localize-redux";

class PatientStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: null,
            stats: null,
            loading: false,
            keyword: ""
        };

        this.searchAndGo = this.searchAndGo.bind(this);
        this.updateKeyword = this.updateKeyword.bind(this);
        this.captureBarcode = this.captureBarcode.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.id) {
            this.searchAndGo({ job_patient_id: this.props.location.state.id })
        } else {
            document.addEventListener("keydown", this.captureBarcode, false);
            this.props.patientClear();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.captureBarcode, false);
    }

    captureBarcode(e) {
        this.barcodeInput.focus();
        return;
    }

    updateKeyword(event) {
        if (this.state.loading) return;
        const target = event.target;
        this.setState({ keyword: target.value });
    }

    searchAndGo(e) {
        e.preventDefault();
        var keyword = this.state.keyword;
        if (this.state.loading || !this.state.keyword) return;
        this.setState({ loading: true });
        authFetch.get('visit/device?barcode=' + keyword, (result) => {
            if (result.success && result.visit && result.visit.vn) {
                let patient = {
                    vn: result.visit.vn,
                    wt: result.visit.wt,
                    ht: result.visit.ht,

                    cid: result.visit.patient.cid,
                    fullname: result.visit.patient.fullname,
                    other_id: result.visit.patient.other_id,
                    tel: result.visit.patient.tel,

                    dob: result.visit.patient.dob,
                    sex: result.visit.patient.sex,
                    bps: result.visit.bp,
                    sbp: null,
                    dbp: null,
                    pulse: null,
                    personals: result.visit.personal
                }
                this.props.patientChange(patient);
                this.props.history.push('patient/measure');
            } else {
                alert("Patient Not Found")
                this.setState({ loading: false });
            }
        });
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div className="register-page page">
                        <main className="page-center">
                            <MainLogo />

                            <div className="logout-wrapper">
                                <div className="menu-topbar">
                                    <MiniClock />
                                    <MiniLanguageToggle />
                                </div>
                                <div className="menu-topbar">
                                    <button onClick={() => { this.props.history.push('/') }}><i className="fa fa-home"></i></button>
                                </div>
                            </div>

                            <div>
                                <div className="page-title">
                                    <h1>{translate("please_scan_barcode")} <i className="fas fa-barcode"></i></h1>
                                    <h2>{translate("to_start")}</h2>
                                </div>
                                <form onSubmit={(e) => this.searchAndGo(e)} className="search-form">
                                    <input type="text" className="form-control" placeholder={translate("search")} onChange={this.updateKeyword} ref={(input) => { this.barcodeInput = input; }} autoFocus />
                                    <button type="submit" className="search-form-btn"><i className="fa fa-search"></i></button>
                                </form>
                            </div>
                        </main>
                        <Loading show={this.state.loading} />
                    </div>
                }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange, patientClear })(PatientStart);