import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
	instructions = [
		"please_insert_your_arm_into_bp_machine",
		"and_press_start_on_the_machine"
	]
	instruction_audio = "04.mp3"
	values = ["sbp", "dbp", "pulse"]

    constructor(port) {
		super();

		this.connect(port);
	}

	connect(port) {
		if(!this.isNative)return;
		this.port = new this.serialport(port,{ // eslint-disable-line no-undef
            baudRate: 115200
		});
	}
	
    read(cb,errorcb,statuscb) {
		if(!this.isNative)return;

        this.port.on("open", (err) => {
			if(err) {
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				statuscb({reading:true, error:false})
				this.parse(cb,errorcb,statuscb)
			}
		});
	
		this.port.on('error', (err) => { 
			console.log('Error: ', err);
			statuscb({reading:false, error:true})
		});
	
		this.port.on('close', (err) => { 
			console.log("Device Closed. Error: ", err);  
			statuscb({reading:false, error:false})  
		});

		this.port.on('disconnect',  (err) => { 
			console.log("Disconnected. Error: ", err);   
			statuscb({reading:false, error:true}) 
		});
	}
	
	parse(cb,errorcb,statuscb) {
		if(!this.isNative)return;
		console.log('parsing');
		var parser = this.port.pipe(new this.delimiter({ delimiter: new Buffer.from('\n') }));
		// console.log(parser)
        // let parser = this.port.pipe(new this.delimiter({delimiter: new Buffer.from('\x03')})); // eslint-disable-line no-undef
		// console.log(parser);
        parser.on('data',  (data) => {
            let raw = data.toString()
			// console.log(data)
			// console.log(raw)
            let start = raw.indexOf('SYS:')
            let sbp = raw.substr(start+4,3)
            start = raw.indexOf('DIA:')
            let dbp = raw.substr(start+4,3)
            start = raw.indexOf('PR:')
            let pulse= raw.substr(start+3,3)
			// console.log(raw,sbp,dbp,pulse)

            try {
              sbp= parseInt(sbp, 10);
              dbp= parseInt(dbp, 10);
              pulse= parseInt(pulse, 10);
            } catch (e) {
              console.log(e)
            }

			// console.log(raw,sbp,dbp,pulse)
            if(sbp && dbp && pulse)
            {
              cb({
                  sbp: sbp,
                  dbp: dbp,
                  pulse: pulse
              })
              statuscb({reading:false, error:false})
              this.close();
            }
        });

        parser.on('error',  (err) => {
            console.error("bpParser Error", err);
			statuscb({reading:false, error:true})
			errorcb(err);
			this.close();
        });
	}
}
export default Device;