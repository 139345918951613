import { createStore } from "redux";
export const PATIENT_CHANGE = "PATIENT_CHANGE";
export const PATIENT_CLEAR = "PATIENT_CLEAR";

// Reducers
const initialState = {
  patient: {
    vn: null,
    id: null,
    cid: null,
    fullname: null,
    address: null,
    birthday: null,
    gender: null,
    phone: null,
    height: null,
    weight: null,
    bp: null,
    splitting: false,
    personals: []
  }
};

export function rootReducer(state = initialState, action) {
  if (action.type === PATIENT_CHANGE) {
    return { ...state, ...{ patient: {...state.patient, ...action.payload} } };
  } else if (action.type === PATIENT_CLEAR) {
    return { ...initialState  };
  }
  return state;
}

// Store
export const store = createStore(rootReducer);

// Action
export function patientChange(payload) {
    return { type: PATIENT_CHANGE, payload }
}

export function patientClear(payload) {
  return { type: PATIENT_CLEAR, payload }
}