import React, { Component } from "react";
import { connect } from 'react-redux'
import Auth from '../helpers/Auth';


class MainLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="main-logo">

          {this.props.patient.fullname ?
            this.props.patient.fullname
            : <img alt="CHECKMATE" src={require('assets/images/checkmate-logo.svg')} width="250" />
          }
        </div>
        {Auth.unit_name ? (
          <div className="page-unit-name">Unit: {Auth.unit_name}</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { patient: state.patient }
};

export default connect(mapStateToProps)(MainLogo);
