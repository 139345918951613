import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
  instructions = [
    "please_insert_your_arm_into_bp_machine",
    "and_press_start_on_the_machine"
  ]
  instruction_audio = "04.mp3"
  values = ["sbp", "dbp", "pulse"]

  constructor(port) {
    super();

    this.connect(port);
  }

  connect(port) {
    if (!this.isNative) return;
    this.port = new this.serialport(port, { // eslint-disable-line no-undef
      baudRate: 115200
    });
  }

  read(cb, errorcb, statuscb) {
    if (!this.isNative) return;

    this.port.on("open", (err) => {
      if (err) {
        console.log(err)
        statuscb({ reading: false, error: true })
      } else {
        console.log("Port opened");
        statuscb({ reading: true, error: false })
        this.parse(cb, errorcb, statuscb)
      }
    });

    this.port.on('error', (err) => {
      console.log('Error: ', err);
      statuscb({ reading: false, error: true })
    });

    this.port.on('close', (err) => {
      console.log("Device Closed. Error: ", err);
      statuscb({ reading: false, error: false })
    });

    this.port.on('disconnect', (err) => {
      console.log("Disconnected. Error: ", err);
      statuscb({ reading: false, error: true })
    });
  }

  parse(cb, errorcb, statuscb) {
    if (!this.isNative) return;
		var parser = this.port.pipe(new this.bytelength({ length: 20 }));  // eslint-disable-line no-undef
    //const parser = port.pipe(new ByteLength({ length: 20 }))

    //console.log(parser)
    parser.on('data', (data) => {
      //console.log(data)
      /*
      var buf = Buffer.from(data, 'hex');
      var bufToStringHex = buf.toString('hex');
      let sbp = parseInt(data[170])
      let dbp = parseInt(bufToStringHex.slice(32, 34))
      let pulse = parseInt(bufToStringHex.slice(36, 38))
      */
      let sbp = data[14]
      let dbp = data[16]
      let pulse = data[18]
      cb({
        sbp: sbp,
        dbp: dbp,
        pulse: pulse
      })
      statuscb({ reading: false, error: false })
      this.close();
    });

    parser.on('error', (err) => {
      console.error("bpParser Error", err);
      statuscb({ reading: false, error: true })
      errorcb(err);
      this.close();
    });
  }
}
export default Device;