import React, { Component } from "react";
import { connect } from 'react-redux'
import { patientChange } from '../state/patient'

import authFetch from '../services/authFetch';

import Loading from '../components/Loading';

import { Translate } from "react-localize-redux";


class SplitQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: null,
            stats: null,
            loading: false,
            number: null,
            saved: false,
            personal_list: []
        };
    }

    componentDidMount() {
        authFetch.get('personal', (result) => {
            this.setState({ loading: true })
            if (result.success) {
                this.loadVisit(result.personal_templates);
                this.setState({ loading: false })
            }
        });
    }


    loadVisit(personal_templates) {
        var personals = [];
        personal_templates.map((item, index) => {
            this.props.patient.personals.map((vitem, vindex) => {
                if (item.id === vitem.id) {
                    item.value = vitem.value;
                    item.note = vitem.note;
                }
            });
            if (item.value === undefined) item.value = "1";
            if (!item.note) item.note = "";
            personals.push(item)
        });

        var set = {
            personal_list: personals
        }
        this.setState(set);
        this.setState({ loading: false });
    }

    numberchange = (number) => {
        this.setState({ number: number });
    }

    doSubmit = () => {
        this.setState({ loading: true });

        var pers = [];
        this.state.personal_list.map((item, index) => {
            pers.push({ id: item.id, value: item.value, note: item.note });
        });
        var data = {
            vn: this.props.patient.vn,
            personal: pers
        }

        authFetch.post('visit/device/personal', data, (result) => {
            this.setState({ loading: false, saved: true });
            this.togglePane()
        });
        return false;
    }

    handlePersonalsChange(id, value) {
        var pers = [];
        this.state.personal_list.map((item, index) => {
            if (item.id === id) {
                item.value = value;
            }
            pers.push(item);
        });
        this.setState({ personal_list: pers });

        var personals = [];
        pers.map((item, index) => {
            personals.push({ id: item.id, value: item.value, note: item.note });
        });
        this.props.patientChange({
            personals: personals
        });
    }

    handlePersonalsNoteChange(id, event) {
        var pers = [];
        this.state.personal_list.map((item, index) => {
            if (item.id === id) {
                item.note = event.target.value;
            }
            pers.push(item);
        });
        this.setState({ personal_list: pers });

        var personals = [];
        pers.map((item, index) => {
            personals.push({ id: item.id, value: item.value, note: item.note });
        });
        this.props.patientChange({
            personals: personals
        });
    }

    togglePane = () => {
        this.props.patientChange({
            splitting: !this.props.patient.splitting
        });
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div>
                        {this.props.patient.splitting ?
                            <div className="split-page">
                                <h4>ซักประวัติ</h4>
                                <div className="pe-list">
                                    {this.state.personal_list.map((item, index) => {
                                        return (
                                            <div className="pe-list-item" key={index}>
                                                <div className="pe-list-item-title">{item.name}:</div>
                                                <div className="pe-list-item-content">
                                                    <div className="pe-list-item-select">
                                                        <button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "1")}>
                                                            <i className={"far fa-" + ((item.value === "1") ? 'check-circle' : 'circle')}></i>
                                                            <strong> {translate('dont_have')}</strong>
                                                        </button>
                                                        <button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "0")}>
                                                            <i className={"far fa-" + ((item.value === "0") ? 'check-circle' : 'circle')}></i>
                                                            <strong> {translate('have')}</strong>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="pe-list-item-note">
                                                    {item.value === "0" ? (
                                                        <input type="text" className="pe-note" onChange={(event) => this.handlePersonalsNoteChange(item.id, event)} value={item.note} />
                                                    ) : (null)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <p className="text-right">
                                    <button className="btn-round" onClick={this.doSubmit}>{translate("save_data")}</button>
                                </p>
                            </div>
                            : null}
                        <button className="split-pane-toggle" onClick={this.togglePane}>
                            <i className={"fas fa-" + ((this.props.patient.splitting) ? 'times' : ((this.state.saved) ? 'check' : 'file-alt'))}></i>
                        </button>
                        <Loading show={this.state.loading} />
                    </div>
                }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange })(SplitQuestion);
