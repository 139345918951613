import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import Loading from '../components/Loading';

class DeviceMeasure extends Component {
    device = null
    timer = null
    inited = false

	constructor(props) {
		super(props);
        this.state = {
            reading: false,
            error: false,
            result: null,
            counter: 30,
            loading:true
        };
        this.read = this.read.bind(this);
	}

	componentDidMount() {
        const reader = require('../helpers/Devices/'+this.props.device.handle).default
        this.device = new reader(this.props.device.active);
        this.read();
    }

    componentWillUnmount() {
        this.cleanup();
    }

    read() {
        this.setState({loading:true})
        this.device.read(
            (result)=>{
                // success
                console.log(result)
                this.setResult(result)
            },
            (err)=>{
                // error
                console.log(err)
            },
            (status)=>{
                this.setState(status)
                this.setState({loading:false})
            }
        );
    }

    retry = () => {
        this.cleanup();
        this.setState({counter:30,reading:false,error:false,result:null})
        this.device.connect(this.props.device.active);
        this.read();
    }

    cleanup = () => {
        if (this.timer) clearInterval(this.timer);
        this.device.close();
        return true;
    }
    
    cancel = () => {
        console.log('cancel')
        this.cleanup()
        this.update()
    }

    update = () => {
        this.props.update(this.state.result);
    }

    manualInput = () => {
        window.doPrompt('please_enter_values',this.device.values,
          (result)=>{
            this.setResult(result)
          },
          ()=>{}
        );
    }

    setResult = (result) => {
        if( this.props.device.type==="bp" && result.sbp && result.dbp ) {
            result.bpExceed = this.calcBpExceed(result.sbp,result.dbp);
        } else if( this.props.device.type==="weight_height" && result.wt && result.ht ) {
            result.bmi = this.calcBmi(result.wt,result.ht);
        } else if( this.props.device.type==="weight" && result.wt && this.props.ht ) {
            result.bmi = this.calcBmi(result.wt,this.props.ht);
        }

        console.log(result)
        
        this.setState({result:result})
        this.cleanup()

        this.timer = setInterval(() => {
            let counter = this.state.counter - 1;

            if (counter === 0) {
                clearInterval(this.timer);
                this.update();
            }
            this.setState({ counter: counter });
        }, 1000)
    }

    calcBmi = (wt,ht) => {
        let bmi_meaning = '';
        let bmi = Math.floor(wt / (ht / 100 * ht / 100));
        if (bmi < 18.5) {
            bmi_meaning = "underweight"
        } else if (bmi < 23) {
            bmi_meaning = "bmi_normal"
        } else if (bmi < 25) {
            bmi_meaning = "overweight"
        } else {
            bmi_meaning = "obesity"
        }
        return {bmi:bmi, meaning:bmi_meaning}
    }

    calcBpExceed = (sbp, dbp) => {
        let result = false;
        if (sbp >= 140) result = true;
        if (dbp >= 90) result = true;
        return result;
    }
  
  	render() {
        let display_result = "";
        if(this.state.result) {
            if(this.props.device.type==="bp") {
                display_result = (
                    <Translate>
                        {({ translate }) => 
                            <div>
                                {this.state.result.bpExceed?
                                <div>
                                    <h2>{translate("your_blood_pressure_exceed_normal_limit")}</h2>
                                    <h3>{translate("please_rest_and_try_again")}</h3>
                                </div>
                                :null}
                                <h4>{translate("blood_pressure")} {this.state.result.sbp}/{this.state.result.dbp} <small><i className="fas fa-heartbeat"></i> {translate("pulse")} {this.state.result.pulse}</small></h4>
                                <button onClick={this.retry} className="btn btn-subtle">ลองใหม่ <i className="fas fa-redo-alt"></i></button>
                                <button onClick={this.update} className="btn btn-round">{translate("ok")} ({this.state.counter}) <i className="fas fa-check"></i></button>
                            </div>
                        }
                    </Translate>
                )
            } else if(this.props.device.type==="weight") {
                display_result = (
                    <Translate>
                        {({ translate }) => 
                            <div>
                                <h4> {translate("weight")} {this.state.result.wt} <small>{translate("kg")}</small></h4>
                                <button onClick={this.retry} className="btn btn-subtle">ลองใหม่ <i className="fas fa-redo-alt"></i></button>
                                <button onClick={this.update} className="btn btn-round">{translate("ok")} ({this.state.counter}) <i className="fas fa-check"></i></button>
                            </div>
                        }
                    </Translate>
                )
            } else if(this.props.device.type==="weight_height") {
                display_result = (
                    <Translate>
                        {({ translate }) => 
                            <div>
                                <h4>
                                    {translate("weight")} {this.state.result.wt} <small>{translate("kg")}</small>
                                    &nbsp; / &nbsp;
                                    {translate("height")} {this.state.result.ht} <small>{translate("cm")}</small> 
                                </h4>
                                {this.state.result.bmi?
                                    <h4>BMI {this.state.result.bmi.bmi} - {translate(this.state.result.bmi.meaning + "")}</h4>
                                :null}
                                <button onClick={this.retry} className="btn btn-subtle">ลองใหม่ <i className="fas fa-redo-alt"></i></button>
                                <button onClick={this.update} className="btn btn-round">{translate("ok")} ({this.state.counter}) <i className="fas fa-check"></i></button>
                            </div>
                        }
                    </Translate>
                )
            }
        }

		return (
			<Translate>
			{({ translate }) => 
				<div>
                    <div className="display-device-name" onClick={this.manualInput}>{this.props.device.name}</div>
                    <h1>{translate("measure_"+this.props.device.type)}</h1>
                    {display_result?
                        <div>
                            {display_result}
                        </div>
                    :
                        <div>
                            {this.state.error?
                                <div>
                                    <h3>{translate("error_reading_from_device")}</h3>
                                    <button onClick={this.cancel} className="btn btn-subtle">ยกเลิก <i className="fas fa-times"></i></button>
                                    <button onClick={this.retry} className="btn btn-round">ลองใหม่ <i className="fas fa-redo-alt"></i></button>
                                </div>
                            :
                                <div>
                                    {this.state.reading?
                                     <div>
                                         {this.device.instructions.map((instruction,index)=><h3 key={index}>{translate(instruction)}</h3>)}
                                         <button onClick={this.cancel} className="btn btn-round">ยกเลิก <i className="fas fa-times"></i></button>
                                         {this.device.instruction_audio?
                                         <audio id="instruction-audio" src={require('assets/sound/'+this.device.instruction_audio)} autoPlay preload="auto"></audio>
                                         :null}
                                    </div>
                                    :<div>Loading...</div>}
                                </div>
                            }
                        </div>
                    }                        
                    <Loading show={this.state.loading} />
				</div>
      		}
			</Translate>
		);
  	}
}

export default DeviceMeasure;