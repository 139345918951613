import React, { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../translations/global.json";

import authFetch from '../services/authFetch';

import { Translate } from "react-localize-redux";

class InitApp extends Component {

	constructor(props) {
		super(props);

		this.state = {
			connection: true,
			alert: null,
			confirm: null,
			warning: null,
      prompt: null,
			info: null,
			inited: false
		}

		this.props.initialize({
			languages: [
				{ name: "English", code: "en" },
				{ name: "Thai", code: "th" }
			],
			translation: globalTranslations,
			options: { defaultLanguage: "th", renderToStaticMarkup }
		});
		this.checkHeartBeat = this.checkHeartBeat.bind(this);

		window.doInfo = (e) => {
			this.setState({ info: e })
		}

		window.doAlert = (e) => {
			this.setState({ alert: e })
		}

		window.doConfirm = (text, ok, no) => {
			this.setState({ confirm: { text: text, ok: ok, no: no } });
    }
    
		window.doPrompt = (text, params, ok, no) => {
			this.setState({ prompt: { text: text, params:params, ok: ok, no: no } });
		}
  }
  
  setParam = (e,param) => {
    let params = this.state.prompt.params;
    params[param+""] = e.target.value;
    this.setState({ prompt: {...this.state.prompt,...{params:params} } });
  }

	componentDidMount() {
		// check connection
		/*
		setInterval(() => {
			this.checkHeartBeat();
		}, 6000)
		*/
	}

	checkHeartBeat() {
		var connection = false;
		authFetch.get('heartbeat', (data) => {
			if (data && data.success) {
				connection = true;
			}
			this.setState({ connection: connection });
		});
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div>
						<div>
							{this.props.children}

							{this.state.connection ? null : <div className="no-connection-alert">Cannot connect to server. Please check your network connection.</div>}

							{this.state.alert ? <div className="popup-alert-box"><span>{this.state.alert}<i onClick={() => { this.setState({ alert: null }) }}>&times;</i></span></div> : null}

							{this.state.info ? <div className="popup-info-box"><span>{this.state.info}<i onClick={() => { this.setState({ info: null }) }}>&times;</i></span></div> : null}

							{this.state.confirm ? (
								<div className="popup-confirm-box">
									<div>
										<i onClick={() => { this.setState({ confirm: null }) }}>&times;</i>
										<p className="text-center">{translate(this.state.confirm.text)}</p>
										<p className="text-center">
											<button type="button" onClick={() => { this.setState({ confirm: null }); this.state.confirm.no() }}>{translate('cancel')}</button>
											<button className="popup-confirm-btn" type="button" onClick={() => { this.setState({ confirm: null }); this.state.confirm.ok() }}>{translate('ok')}</button>
										</p>
									</div>
								</div>) : null}

                {this.state.prompt ? (
								<div className="popup-prompt-box">
									<div>
										<i onClick={() => { this.setState({ prompt: null }) }}>&times;</i>
										<p className="text-center">{translate(this.state.prompt.text)}</p>
										<p className="text-center">
                      { this.state.prompt.params.map((param,index)=>{
                        return <input key={index} type="text" onChange={ (e)=>this.setParam(e,param) } placeholder={param} />
                      })}
											<button type="button" onClick={() => { this.setState({ prompt: null }); this.state.prompt.no() }}>{translate('cancel')}</button>
											<button className="popup-prompt-btn" type="button" onClick={() => { 
                        this.state.prompt.ok(this.state.prompt.params)
                        this.setState({ prompt: null });
                      }}>{translate('ok')}</button>
										</p>
									</div>
								</div>) : null}

						</div>
					</div>
				}
			</Translate>
		);
	}
}
export default withLocalize(InitApp);