import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
	instructions = [
		"please_step_on_weight_scale"
	]
	instruction_audio = "05_1.mp3"
	values = ["wt"]

    constructor(port) {
		super();
		this.connect(port);
	}

	connect(port) {
		if(!this.isNative)return;
		this.port = new this.serialport(port,{ // eslint-disable-line no-undef
            baudRate:9600
		});
	}
	
    read(cb,errorcb,statuscb) {
		if(!this.isNative)return;

        this.port.on("open", (err) => {
			if(err){
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				this.parse(cb,errorcb,statuscb)
				statuscb({reading:true, error:false})
			}
		});
	
		this.port.on('error', (err) => { 
			console.log('Error: ', err);
			statuscb({reading:false, error:true})
		});
	
		this.port.on('close', (err) => { 
			console.log("Device Closed. Error: ", err);  
			statuscb({reading:false, error:false})  
		});

		this.port.on('disconnect', function (err) { 
			console.log("Disconnected. Error: ", err);   
			statuscb({reading:false, error:true}) 
		});
	}
	
	parse(cb,errorcb,statuscb) {
		if(!this.isNative)return;
		var parser = this.port.pipe(new this.delimiter({ delimiter: new Buffer.from('\n') }));  // eslint-disable-line no-undef
		parser.on('data',  (data) => {
			console.log(data)
			let raw = data.toString('ascii').split(',')
			let indexWt = raw.findIndex(element => element === 'Wk') + 1
			console.log(raw[indexWt])
			cb({
				wt: raw[indexWt]
			})
			statuscb({reading:false, error:false})
			this.close();
		})

        parser.on('error',  (err) => {
            console.error("bpParser Error", err);
			statuscb({reading:false, error:true})
			errorcb(err);
			this.close();
        });
	}
}
export default Device;