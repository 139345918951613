import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
	instructions = [
		"please_insert_your_arm_into_bp_machine",
		"and_press_start_on_the_machine"
	]
	instruction_audio = "04.mp3"
	values = ["sbp", "dbp", "pulse"]

    constructor(port) {
		super();

		this.connect(port);
	}

	connect(port) {
		if(!this.isNative)return;
		this.port = new this.serialport(port,{ // eslint-disable-line no-undef
            baudRate:9600
		});
	}
	
    read(cb,errorcb,statuscb) {
		if(!this.isNative)return;

        this.port.on("open", (err) => {
			if(err){
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				statuscb({reading:true, error:false})
				this.parse(cb,errorcb,statuscb)
			}
		});
	
		this.port.on('error', (err) => { 
			console.log('Error: ', err);
			statuscb({reading:false, error:true})
		});
	
		this.port.on('close', (err) => { 
			console.log("Device Closed. Error: ", err);  
			statuscb({reading:false, error:false})  
		});

		this.port.on('disconnect',  (err) => { 
			console.log("Disconnected. Error: ", err);   
			statuscb({reading:false, error:true}) 
		});
	}
	
	parse(cb,errorcb,statuscb) {
		if(!this.isNative)return;
        let parser = this.port.pipe(new this.delimiter({delimiter: new Buffer.from('\x03')})); // eslint-disable-line no-undef
        parser.on('data',  (data) => {
            let raw = data.toString('ascii')
            let start = raw.indexOf('S')
            let sbp = raw.substr(start+1,3)
            start = raw.indexOf('D')
            let dbp = raw.substr(start+1,3)
            start = raw.indexOf('P')
            let pulse= raw.substr(start+1,3)
            cb({
                sbp: sbp,
                dbp: dbp,
                pulse: pulse
            })
			statuscb({reading:false, error:false})
			this.close();
        });

        parser.on('error',  (err) => {
            console.error("bpParser Error", err);
			statuscb({reading:false, error:true})
			errorcb(err);
			this.close();
        });
	}
}
export default Device;